import { type ClassValue, clsx } from "clsx";
import qs from "query-string";
import { extendTailwindMerge } from "tailwind-merge";
import { IconCheck } from "@tabler/icons-react";
import { AuthRoutes } from "@/lib/url";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import moment from "moment-timezone";
import {
  ATTEND_TYPE,
  DEFAULT_TBD_END_EPOC,
  DEFAULT_TBD_START_EPOC,
  EVENT_IMAGE_SIZE,
  EVENT_JOIN_STATUS,
  ROLES
} from "@key.ai/constants";
import {
  APIEventSession,
  EventSession,
  inviteEmail,
  Location,
  SessionParticipationPayload,
  UserInfo
} from "@/types/cusom-types";
import {
  backdropBlur,
  borderRadius,
  boxShadow,
  colors,
  fontSize,
  lineHeight,
  spacing
} from "@/tailwind-configs";
import { useTimezones } from "@/hooks/use-timezones";
import { v4 } from "uuid";
import { Clock, CloseCircle, Note, Slash, TickCircle } from "iconsax-react";

dayjs.extend(advancedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      "font-size": [
        {
          text: [...Object.keys(fontSize)]
        }
      ],
      leading: Object.keys(lineHeight),
      "text-color": [{ text: [...Object.keys(colors)] }],
      p: [{ p: [...Object.keys(spacing)] }],
      shadow: [{ shadow: [...Object.keys(boxShadow)] }],
      "backdrop-blur": [...Object.keys(backdropBlur)],
      rounded: [
        {
          tr: [...Object.keys(borderRadius)],
          tl: [...Object.keys(borderRadius)],
          br: [...Object.keys(borderRadius)],
          bl: [...Object.keys(borderRadius)]
        }
      ],
      gap: [...Object.keys(spacing)]
    }
  }
});

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function cnWithoutTWMerge(...inputs: ClassValue[]) {
  return clsx(inputs);
}

export function redirectToSignIn({
  callbackUrl,
  ...others
}: {
  callbackUrl?: string;
  [key: string]: string | undefined;
}) {
  if (typeof window === "undefined") return;
  window.location.href = qs.stringifyUrl(
    { url: `/auth`, query: { callbackUrl, ...others } },
    { skipEmptyString: true, skipNull: true }
  );
}

export const generateIntroJson = ({
  questions,
  calendar,
  facebook,
  instagram,
  linkedin,
  x,
  other
}: {
  questions?: { question: string; answer: string }[];
  facebook?: string;
  instagram?: string;
  x?: string;
  other?: string;
  calendar?: string;
  linkedin?: string;
}) => {
  const delta = [];

  for (const question of questions || []) {
    delta.push(
      { attributes: { bold: true }, insert: question.question },
      { insert: `\n${question.answer || "-"}\n` }
    );
  }

  if (facebook) {
    delta.push({ insert: "\n#️⃣ Facebook: " }, { attributes: { link: facebook }, insert: "Link" });
  }
  if (instagram) {
    delta.push({ insert: "\n#️⃣ Instagram: " }, { attributes: { link: instagram }, insert: "Link" });
  }
  if (x) {
    delta.push({ insert: "\n#️⃣ X: " }, { attributes: { link: x }, insert: "Link" });
  }
  if (other) {
    delta.push({ insert: "\n#️⃣ Website: " }, { attributes: { link: other }, insert: "Link" });
  }
  if (calendar) {
    delta.push({ insert: "\n#️⃣ Calendar: " }, { attributes: { link: calendar }, insert: "Link" });
  }
  if (linkedin) {
    delta.push({ insert: "\n#️⃣ Linkedin: " }, { attributes: { link: linkedin }, insert: "Link" });
  }

  return delta;
};

export interface EventImageSize {
  "500*500"?: string;
  "500*500_optimized"?: string;
  "500*250"?: string;
  "500*250_optimized"?: string;
  webp?: string;
  heic?: string;
}

export const IMAGE_URL = "https://dev-images.bprnt.com";

export const DEFAULT_IMAGE_URL = `${IMAGE_URL}/event_banners/design1.jpeg`;
export const DEFAULT_CALENDER_COVER_IMAGE_URL = `https://storage.googleapis.com/community-dev-404523.appspot.com/content/f7303f35-4b27-40cb-b7ae-19f7faa5b610.png`;

export const STRIPE_PUBLIC_KEY =
  "pk_test_51NtEMJBhB8oEB96lkAmXi8zSAHE9HYRSKaoD5ThXhecPrAUuXr4HLxEAmLWXs9xcxdfk9va0180MTwHHtYPIFqaN009YmwnMR3";

export const eventImageSizeMapper = (image_map: EventImageSize) => {
  if (image_map) {
    if (image_map["webp"] != null) {
      return image_map["webp"];
    } else if (image_map["500*250_optimized"]) {
      return image_map["500*250_optimized"];
    } else if (image_map["500*250"]) {
      return image_map["500*250"];
    } else if (image_map["500*500_optimized"]) {
      return image_map["500*500_optimized"];
    } else if (image_map["500*500"]) {
      return image_map["500*500"];
    } else {
      return DEFAULT_IMAGE_URL;
    }
  } else {
    return DEFAULT_IMAGE_URL;
  }
};

export const getAttendStatus = (status: string | null) => {
  switch (status) {
    case EVENT_JOIN_STATUS.GOING:
      return {
        label: "Going",
        icon: TickCircle,
        ...JOIN_STATUS_COLORS.going,
        color: `text-[${JOIN_STATUS_COLORS.going.fontColor}]`,
        bgColor: `bg-[${JOIN_STATUS_COLORS.going.themeColor}]`
      };
    case EVENT_JOIN_STATUS.NOT_GOING:
      return {
        label: "Declined",
        icon: IconCheck,
        ...JOIN_STATUS_COLORS.declined,
        color: `text-[${JOIN_STATUS_COLORS.declined.fontColor}]`,
        bgColor: `bg-[${JOIN_STATUS_COLORS.declined.themeColor}]`
      };
    case EVENT_JOIN_STATUS.GOING_ON_APPROVAL:
      return {
        label: "Pending Approval",
        icon: Clock,
        ...JOIN_STATUS_COLORS.pending,
        color: `text-[${JOIN_STATUS_COLORS.pending.fontColor}]`,
        bgColor: `bg-[${JOIN_STATUS_COLORS.pending.themeColor}]`
      };
    case EVENT_JOIN_STATUS.WAITLIST:
      return {
        label: "Waitlist",
        icon: Note,
        ...JOIN_STATUS_COLORS.waitlisted,
        color: `text-[${JOIN_STATUS_COLORS.waitlisted.fontColor}]`,
        bgColor: `bg-[${JOIN_STATUS_COLORS.waitlisted.themeColor}]`
      };
    case EVENT_JOIN_STATUS.REJECT:
      return {
        label: "Rejected",
        icon: Slash,
        ...JOIN_STATUS_COLORS.event_reject,
        color: `text-[${JOIN_STATUS_COLORS.event_reject.fontColor}]`,
        bgColor: `bg-[${JOIN_STATUS_COLORS.event_reject.themeColor}]`
      };
    case EVENT_JOIN_STATUS.REVOKE:
      return {
        label: "Not Going",
        icon: CloseCircle,
        ...JOIN_STATUS_COLORS.revoke,
        color: `text-[${JOIN_STATUS_COLORS.revoke.fontColor}]`,
        bgColor: `bg-[${JOIN_STATUS_COLORS.revoke.themeColor}]`
      };
    default:
      return {
        label: "Invited",
        icon: Clock,
        ...JOIN_STATUS_COLORS.invited,
        color: `text-[${JOIN_STATUS_COLORS.invited.fontColor}]`,
        bgColor: `bg-[${JOIN_STATUS_COLORS.invited.themeColor}]`
      };
  }
};

export const JOIN_STATUS_COLORS: Record<
  | "invited"
  | "going"
  | "not_going"
  | "pending"
  | "revoke"
  | "event_reject"
  | "waitlisted"
  | "declined",
  { fontColor: string; themeColor: string }
> = {
  invited: {
    fontColor: "#2196F3",
    themeColor: "rgba(33, 150, 243, 0.08)"
  },
  going: {
    fontColor: "#7CB342",
    themeColor: "rgba(124, 179, 66, 0.08)"
  },
  not_going: {
    fontColor: "#CD3333",
    themeColor: "rgba(205, 51, 51, 0.08)"
  },
  pending: {
    fontColor: "#D0A31B",
    themeColor: "rgba(255, 204, 50, 0.08)"
  },
  revoke: {
    fontColor: "#229af1",
    themeColor: "#daf0ff"
  },
  event_reject: {
    fontColor: "#FF9800",
    themeColor: "rgba(255, 204, 50, 0.08)"
  },
  waitlisted: {
    fontColor: "#AB47BC",
    themeColor: "rgba(171, 71, 188, 0.08)"
  },
  declined: {
    fontColor: "#424242",
    themeColor: "rgba(66, 66, 66, 0.08)"
  }
};

export const getAttendType = (attend_type: string | undefined) => {
  switch (attend_type) {
    case "in_person":
      return "In Person";
    case "tbd":
      return "To Be Decided";
    case "virtual":
      return "Virtual";
    case "hybrid":
      return "Hybrid";
    default:
      return attend_type || "";
  }
};

const ATTEND_STATUS_COLORS = {
  in_person: {
    label: "In Person",
    icon: IconCheck,
    color: "#FF4500",
    bgColor: "rgba(255, 69, 0, 0.08)"
  },
  virtual: {
    label: "Virtual",
    icon: IconCheck,
    color: "#9400D3",
    bgColor: "rgba(148, 0, 211, 0.08)"
  },
  hybrid: {
    label: "Hybrid",
    icon: IconCheck,
    color: "rgb(103, 186, 186)",
    bgColor: "rgba(151, 255, 255, 0.35)"
  },
  tbd: {
    label: "To Be Decided",
    icon: IconCheck,
    color: "#F1408F",
    bgColor: "rgba(241, 64, 143, 0.08)"
  },
  default: {
    label: "Unknown",
    icon: IconCheck,
    color: "#808080",
    bgColor: "rgba(128, 128, 128, 0.08)"
  }
} as const;

type AttendStatusKey = keyof typeof ATTEND_STATUS_COLORS;

export const getAttendStatusColors = (type?: string | null) => {
  if (!type) return ATTEND_STATUS_COLORS.default;

  const normalizedType = type.toLowerCase().replace(" ", "_") as AttendStatusKey;

  if (normalizedType in ATTEND_STATUS_COLORS) {
    return ATTEND_STATUS_COLORS[normalizedType];
  }

  return ATTEND_STATUS_COLORS.default;
};

export const areEpochsOnSameDay = (epoch1: number, epoch2: number, timeZone: string) => {
  // Convert epochs to Day.js objects
  const date1 = dayjs.unix(epoch1).tz(timeZone);
  const date2 = dayjs.unix(epoch2).tz(timeZone);

  // Check if the dates are on the same day
  return date1.isSame(date2, "day");
};

export const extractPremiseName = (header: string | undefined) => header?.split(",")[0] || "";

export const checkIfTBD = (start_epoc: number = 0, end_epoc: number = 0) => {
  if (
    Number(start_epoc) === Number(DEFAULT_TBD_START_EPOC) &&
    Number(end_epoc) === Number(DEFAULT_TBD_END_EPOC)
  ) {
    return true;
  } else {
    return false;
  }
};

export const checkAccessStatus = (data: any) => {
  switch (data) {
    case ROLES.CREATOR:
      return true;
    case ROLES.HOST:
      return data?.status === EVENT_JOIN_STATUS.GOING;
    default:
      return false;
  }
};

export const calculateTimeDifference = (startEpoch: number, endEpoch: number) => {
  const startDateTime = dayjs.unix(startEpoch);
  const endDateTime = dayjs.unix(endEpoch);

  // Calculate the difference in milliseconds
  const diffMilliseconds = endDateTime.diff(startDateTime);

  const diffDuration = dayjs.duration(diffMilliseconds);

  const days = diffDuration.days();
  const hours = diffDuration.hours();
  const minutes = diffDuration.minutes();

  let formattedTime = "";
  if (days > 0) formattedTime += `${days}d `;
  if (hours > 0 || days > 0) formattedTime += `${hours}hr `;
  if (minutes > 0 || hours > 0 || days > 0) formattedTime += `${minutes}m`;

  return formattedTime.trim();
};

export const convertCurrentTimeToEpocBasedOnZone = (date: string, time: string, zone: string) => {
  const dateTimeString = `${date}T${time}`;

  // Set the time zone for the Day.js object
  const dateTime = dayjs(dateTimeString).tz(zone);

  // Convert the Day.js object to Unix timestamp (seconds since Unix epoch)
  return Math.floor(dateTime.unix());
};

export const getStandardTimeFromEpoch = (epochTimestamp: number = 0) => {
  const localTimeZone = dayjs.tz.guess();
  const dtObject = dayjs.unix(epochTimestamp).tz(localTimeZone);
  return dtObject.format("hh:mm A");
};

export const extractAddressComponents = (addressComponents: any) => {
  const location = {
    sublocality: "",
    locality: "",
    administrative_area_level_3: "",
    administrative_area_level_2: "",
    administrative_area_level_1: "",
    country: "",
    latLng: {
      lat: 0,
      lng: 0
    },
    placeId: ""
  };
  for (const component of addressComponents) {
    if (component.types.includes("locality")) {
      location.locality = component.long_name;
    } else if (component.types.includes("administrative_area_level_1")) {
      location.administrative_area_level_1 = component.long_name;
    } else if (component.types.includes("administrative_area_level_2")) {
      location.administrative_area_level_2 = component.long_name;
    } else if (component.types.includes("administrative_area_level_3")) {
      location.administrative_area_level_3 = component.long_name;
    } else if (component.types.includes("country")) {
      location.country = component.short_name;
    } else if (component.types.includes("sublocality")) {
      location.sublocality = component.long_name;
    } else if (component.types.includes("neighborhood")) {
      location.sublocality = component.long_name;
    } else if (component.types.includes("premise")) {
      location.sublocality = component.long_name;
    }
  }
  return location;
};

export const extractFullAddress = (place: any, description: string) => {
  const premiseName = description?.split(",")[0].trim() || "";
  const formattedAddress = place?.formatted_address || "";

  const formattedAddressWithoutPremise = formattedAddress?.startsWith(premiseName)
    ? formattedAddress?.slice(premiseName?.length + 2)
    : formattedAddress;

  return (
    premiseName + (formattedAddressWithoutPremise ? ", " + formattedAddressWithoutPremise : "")
  );
};

export function generateTimeOptions(startTime: any, endTime: any, interval: any) {
  const timeOptions = [];
  let currentTime = startTime;

  while (currentTime <= endTime) {
    const hours = Math.floor(currentTime / 60);
    const minutes = currentTime % 60;
    let amPm = "AM";

    // Adjust hours and AM/PM
    let displayHours = hours % 12;
    if (displayHours === 0) {
      displayHours = 12;
    }
    if (hours >= 12) {
      amPm = "PM";
    }

    const label = `${padZero(displayHours)}:${padZero(minutes)} ${amPm}`;
    const value = `${currentTime}`;

    timeOptions.push({ label, value });

    currentTime += interval;
  }

  return timeOptions;
}

function padZero(num: any) {
  return num < 10 ? `0${num}` : num;
}

export const getCurrentHours = () => {
  const date = dayjs("2024-05-23T15:45:00");
  return date.hour();
};

export const getCurrentMinutes = () => {
  const date = dayjs("2024-05-23T15:45:00");
  return date.minute();
};
export const getStandardDateTimeFromStartAndEndEpochs = (startEpoch: number, endEpoch: number) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const startFormattedDate = dayjs.unix(startEpoch).tz(timeZone).format("ddd, MMMM DD");
  const endFormattedDate = dayjs.unix(endEpoch).tz(timeZone).format("ddd, MMMM DD");
  const timeZoneAbbreviation = dayjs.unix(startEpoch).tz(timeZone).format("z") || "UTC";

  return `${startFormattedDate} - ${endFormattedDate} (${timeZoneAbbreviation})`;
};

function formatTimeFromEpoch(epoch: any, timeZone: any) {
  return dayjs.unix(epoch).tz(timeZone).format("hh:mm");
}

export const getStandardTimeFromStartAndEndEpochsWithDates = (
  startEpoch: number,
  endEpoch: number
) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const startTime = formatTimeFromEpoch(startEpoch, timeZone);
  const endTime = formatTimeFromEpoch(endEpoch, timeZone);
  const startDate = dayjs.unix(startEpoch).tz(timeZone).format("MMM DD");
  const endDate = dayjs.unix(endEpoch).tz(timeZone).format("MMM DD");
  const timeZoneAbbreviation = dayjs.unix(startEpoch).tz(timeZone).format("A") || "UTC";

  return `${startTime} - ${endDate}, ${endTime} ${timeZoneAbbreviation}`;
};

export const prepareAliasDeciderURL = (aliasId: string) => `api/v1/proxy/alias/${aliasId}`;

export const prepareGetProxyAliasURL = (alias: string, passcode?: string) =>
  `api/v2/proxy/events/${alias}${passcode ? "?passcode=" + passcode : ""}`;

export const prepareGetCalenderAliasURL = (alias: string) => `api/v2/calendar/proxy/a/${alias}`;

export const prepareFetchProfileURL = (username: string) => `api/v1/proxy/users/${username}`;

export const prepareGuestViewObject = (user: inviteEmail, role: string) => {
  return {
    role,
    name: user.name || "",
    email: user.email,
    id: user.id || "",
    profile_photo: user?.profile_photo,
    social_info: user?.social_info,
    firstname: user?.firstname || "",
    lastname: user?.lastname || "",
    username: user?.username || "",
    profile_optimized_image: user?.profile_optimized_image || ""
  };
};

export function getAvatarName(name: string): string {
  const [firstWord = "", lastWord = ""] = (name || "").split(" ");
  return (firstWord[0] + (lastWord[0] || "")).toUpperCase();
}

export const calculateHoursLeft = (
  startEpoc: number,
  endEpoc: number
): { timeString: string; status: "ongoing" | "upcoming" | "ended" } => {
  const eventTime = dayjs.unix(startEpoc);
  const eventEndTime = dayjs.unix(endEpoc);
  const currentTime = dayjs();

  const startDifference = eventTime.diff(currentTime, "milliseconds");
  const endDifference = eventEndTime.diff(currentTime, "milliseconds");

  if (startDifference <= 0) {
    return {
      timeString: "00:00",
      status: endDifference <= 0 ? "ended" : "ongoing"
    };
  }

  const months = eventTime.diff(currentTime, "months");
  const days = eventTime.diff(currentTime.add(months, "months"), "days");
  const hours = eventTime.diff(currentTime.add(months, "months").add(days, "days"), "hours");
  const minutes = eventTime.diff(
    currentTime.add(months, "months").add(days, "days").add(hours, "hours"),
    "minutes"
  );

  const timeString =
    (months > 0 ? `${months}mo ` : "") +
    (days > 0 ? `${days}d ` : "") +
    (hours > 0 ? `${hours}h ` : "") +
    `${minutes}m`;

  return {
    timeString,
    status: "upcoming"
  };
};

export const isObjectEmptyOrNull = (obj: Record<string, any> | null | undefined): boolean => {
  // Check if the object is null, undefined, or an empty object
  return obj == null || Object.keys(obj).length === 0;
};

export const sessionParticipationUpdationHandler = (
  eventSessionsData: APIEventSession[] | null | undefined,
  status: "going" | "not_going"
): SessionParticipationPayload[] => {
  const currentTime = dayjs();

  const allSessions = (eventSessionsData ?? []).map((session) => {
    const isSessionActive = dayjs.unix(parseInt(session.end_epoc)).isAfter(currentTime);
    const finalStatus = isSessionActive || eventSessionsData?.length === 1 ? status : "not_going";

    return {
      event_session_id: session?.id ?? "",
      status: finalStatus
    };
  });

  if (status === "not_going" && allSessions.length > 0) {
    allSessions[0].status = "going";
  }

  return allSessions;
};

export const preparePaymentRequestObject = (
  sessionsArray: any,
  status: string,
  paymentIntent: any,
  ticketId: string
) => {
  const session_participation = sessionsArray.map((session: any) => ({
    event_session_id: session.id,
    status: session.status || status,
    participationID: session?.participation_details?.id
  }));
  return {
    paymentIntentId: paymentIntent,
    ticket: ticketId,
    session_participation
  };
};

export const preparePatchRequestObject = (sessionsArray: any, status: string, ticketId: string) => {
  const session_participation = sessionsArray.map((session: any) => ({
    event_session_id: session.id,
    status: session.status || status,
    participationID: session?.participation_details?.id
  }));
  return { session_participation, ticket: ticketId };
};

export const createDirectPaymentIntentURL = (userId: any, eventId: string) =>
  `api/v3/users/${userId}/events/${eventId}/stripe-payment-intent`;

export const updateDirectPaymentIntentURL = (userId: any, eventId: string, intentId: string) =>
  `api/v3/users/${userId}/events/${eventId}/stripe-payment-intent/${intentId}`;

export const getEventURL = (eventId: string) => {
  return `api/v2/events/${eventId}`;
  // return BACKEND_BASE_URL + APIV2 + EVENTS_API.EVENTS_V2;
};

export const getEventIdFromAlias = (alias: string) => `api/v2/proxy/checkAlias/${alias}`;

export const PRICE_UNITS_LIST = [
  {
    label: "USD",
    value: "usd",
    icon: "/assets/dollar-circle.svg",
    minValue: 0.5,
    sign: "$"
  },
  {
    label: "SGD",
    value: "sgd",
    icon: "/assets/dollar-circle.svg",
    minValue: 0.67,
    sign: "$"
  }
];

export const getCurrencyInfo = (currency: string) => {
  return PRICE_UNITS_LIST.find((price) => price.value === currency) || PRICE_UNITS_LIST[0];
};

export const createStripeAccountLinkURL = (userId: string) =>
  `api/v3/users/${userId}/stripe/create-account-link`;

/**Manage Ticket URL's */
export const prepareManageTicketURL = (eventId: string) => `api/v2/events/${eventId}/tickets`;

/**Manage Ticket URL's */
export const prepareQuestinaireURL = (eventId: string) => `api/v1/events/${eventId}/questions`;

export const prepareCancelEventURL = (eventId: string) => {
  `api/v2/events/${eventId}`;
};

export const checkIsAlphaNumeric = (value: string) => {
  const alphanumericRegex = /^[a-zA-Z0-9]+$/;
  return alphanumericRegex.test(value);
};

export const EVENT_TITLE_MAX_CHARACTERS: number = 60;
export const QUESTION_MAX_CHARACTERS: number = 255;

export const checkStringHasSpecialCharacters = (value: string) => /[#\s/]/.test(value);

export const USERNAME_LENGTH = 6;

export const USERNAME_VALIDATION_MESSAGE = `The username should be at least ${USERNAME_LENGTH} characters long.`;
export const ALIAS_URL_VALIDATION_MESSAGE = `Alias Url should be at least ${USERNAME_LENGTH} characters long.`;
export const ALIAS_NOT_FOUND = `The Platform doesn't have the page what you are trying to access`;

export const createOrGetReminder = (eventId: string) => {
  return `api/v2/events/${eventId}/event-sessions/event-reminder`;
};

export const getSessionReminderURL = (eventId: string, sessionId: string) => {
  return `api/v2/events/${eventId}/event-sessions/${sessionId}/event-reminder`;
};

export function minutesFromMidnight() {
  const hour = dayjs().format("HH"); // Use 24-hour format
  const minute = dayjs().format("mm");

  // Convert hours and minutes to integers
  const hoursInMinutes = parseInt(hour, 10) * 60;
  const minutes = parseInt(minute, 10);

  // Total minutes from midnight
  return hoursInMinutes + minutes;
}
export const sortSessionsByStartEpoch = (events: any) => {
  const sortedEvents = [...events];
  sortedEvents.sort((a, b) => a.start_epoc - b.start_epoc);
  return sortedEvents;
};

export const prepareEventAliasURL = (eventAlias: string) => {
  return `${window.origin}/${eventAlias}`;
};

export const getCurrentEpoc = () => {
  return Math.trunc(new Date().getTime() / 1000);
};

export function downloadFromUrl(url: string, target: string = "_self") {
  const link = document.createElement("a");
  link.href = url;
  link.target = target;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const readFile = (file: File) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
};

export const checkEventImageSize = (image_map: EventImageSize | undefined) => {
  let size = EVENT_IMAGE_SIZE;
  if (image_map) {
    if (image_map["500*250_optimized"]) {
      size = "500*250_optimized";
    } else if (image_map["500*250"]) {
      size = "500*250";
    } else if (image_map["500*500_optimized"]) {
      size = "500*500_optimized";
    } else if (image_map["500*500"]) {
      size = "500*500";
    }
  }
  return size;
};

export const setEndEpocToLastTimeOfDay = (start_epoc: number, time_zone?: string) => {
  const startTime = time_zone ? moment.unix(start_epoc).tz(time_zone) : moment.unix(start_epoc);
  const endTime = startTime.hour(23).minute(30).second(0);
  return time_zone ? endTime.tz(time_zone).unix() : endTime.unix();
};

export const getUserInitials = (user: UserInfo): string => {
  if (user?.firstname && user?.lastname) {
    return `${user.firstname.charAt(0)}${user.lastname.charAt(0)}`;
  }

  if (user?.name) {
    const [firstName = "", lastName = ""] = user.name.split(/\s+/);
    return (firstName.charAt(0) + lastName.charAt(0)).toUpperCase() || firstName.substring(0, 2);
  }

  if (user?.email) {
    const matches = user.email.match(/^[^@]+/);
    if (matches) {
      const parts = matches[0].split(/[.\s]+/);
      if (parts.length > 1) {
        return `${parts[0].charAt(0)}${parts[1].charAt(0)}`;
      } else if (parts.length === 1) {
        return parts[0].substring(0, 2);
      }
    }
  }

  return "";
};

export const formatDateToEpoch = (date: Date) => {
  if (!(date instanceof Date)) {
    throw new Error("Invalid date: Input is not a Date object.");
  }
  return Math.floor(date.getTime() / 1000);
};

export const getDateFromEpochWithDayjs = (epoch: number) => {
  const date = dayjs.unix(epoch);
  const day = date.date();
  const month = date.format("MMM");
  const year = date.year();

  return { day, month, year };
};

export const formatTimeRange = (startEpoch: number, endEpoch: number, tz: string) => {
  try {
    const formatString = "h:mm A";
    const startTime = moment.unix(startEpoch).format(formatString);
    const endTime = moment.unix(endEpoch).format(formatString);

    const startDate = moment.unix(startEpoch).toDate();

    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const tzAbbr = new Intl.DateTimeFormat("en-US", {
      timeZone: userTimezone,
      timeZoneName: "shortGeneric"
    })
      .formatToParts(startDate)
      .find((part) => part?.type === "timeZoneName")?.value;

    return `${startTime} - ${endTime}, ${tzAbbr}`;
  } catch (err) {
    const formatString = "h:mm A";
    const startTime = moment.unix(startEpoch).format(formatString);
    const endTime = moment.unix(endEpoch).format(formatString);

    return `${startTime} - ${endTime}`;
  }
};

// Function to get UTC offset in hours for a specific time zone
export function getUTCOffset(timeZone: string): number {
  let date = moment.tz(timeZone);

  let offsetInMinutes = date.utcOffset();

  let hours = Math.floor(offsetInMinutes / 60);
  let minutes = offsetInMinutes % 60;

  return hours + minutes / 60;
}

export const calculateTimeDifferenceOffset = (timeZone1: string, timeZone2: string) => {
  let offset1 = getUTCOffset(timeZone1);
  let offset2 = getUTCOffset(timeZone2);
  return offset1 - offset2;
};

interface LocationDetails {
  latLng: {
    lat: number;
    lng: number;
  };
  country: string;
  placeId: string;
  locality: string;
  sublocality: string;
  administrative_area_level_1: string;
  administrative_area_level_2: string;
  administrative_area_level_3: string;
}

export const getCityAndLocality = (location: LocationDetails): string => {
  let cityAndLocality = "";

  const locality = location?.sublocality || location?.locality;

  if (location?.administrative_area_level_3) {
    if (locality && locality !== location?.administrative_area_level_3) {
      cityAndLocality = `${locality}, ${location?.administrative_area_level_3}`;
    } else {
      cityAndLocality = location?.administrative_area_level_3 || "";
    }
  }

  const administrativeAreaLevel2 = location?.administrative_area_level_2 || "";
  const administrativeAreaLevel1 = location?.administrative_area_level_1 || "";

  // Construct the full address
  const fullAddress = [
    cityAndLocality,
    administrativeAreaLevel2,
    administrativeAreaLevel1,
    location?.country
  ]
    .filter((part) => part)
    .join(", ");

  return fullAddress;
};

export function getCurrentTimeNearestInterval(interval: any) {
  const now = new Date();
  const minutes = now.getMinutes();
  const remainder = minutes % interval;
  const nextInterval = remainder === 0 ? 0 : interval - remainder;
  now.setMinutes(minutes + nextInterval);
  now.setSeconds(0);
  now.setMilliseconds(0);
  return now;
}

export const getTimeOptionsForDate = (
  startDate: any,
  endDate: any,
  interval: any,
  startTimeOptions: any,
  endTimeOptions: any
) => {
  const now = new Date();
  const isToday = now.toDateString() === startDate.toDateString();
  const isSameDay = startDate.toDateString() === endDate.toDateString();

  let startTime = "";
  let endTime = "";
  let filteredStartTimeOptions = startTimeOptions;
  let filteredEndTimeOptions = endTimeOptions;

  if (isToday) {
    const nearestTime = getCurrentTimeNearestInterval(interval);
    const startTimeMinutes = nearestTime.getHours() * 60 + nearestTime.getMinutes();
    const endTimeMinutes = startTimeMinutes + 60; // 1 hour after start time

    startTime =
      startTimeOptions.find((option: any) => parseInt(option.value, 10) >= startTimeMinutes)
        ?.value || "";
    endTime =
      endTimeOptions.find((option: any) => parseInt(option.value, 10) >= endTimeMinutes)?.value ||
      "";

    filteredStartTimeOptions = startTimeOptions.filter(
      (option: any) => parseInt(option.value, 10) >= startTimeMinutes
    );
    filteredEndTimeOptions = endTimeOptions.filter(
      (option: any) => parseInt(option.value, 10) >= endTimeMinutes
    );

    // if (startTimeMinutes >= 23 * 60) {
    //   // 11 PM or later
    //   const options = generateTimeOptions(0, 24 * 60 - 30, 30);
    //   // setEndTimeOptions(options);
    //   // setEndTimeOptions(generateTimeOptions(0, 24 * 60 - 30, 30));
    //   const newEndDate = new Date(startDate);
    //   newEndDate.setDate(startDate.getDate() + 1);
    //   const endTimeMinutes = (startTimeMinutes + 60) % (24 * 60);
    //   endTime = options.find(
    //     (option) => parseInt(option.value, 10) >= endTimeMinutes
    //   )?.value;
    //   // formik.setFieldValue("endTime", updatedEndTime);
    //   // formik.setFieldValue("endDate", newEndDate);
    // }
  } else {
    filteredStartTimeOptions = generateTimeOptions(0, 24 * 60 - 30, interval);
    filteredEndTimeOptions = generateTimeOptions(0, 24 * 60 - 30, interval);
  }
  if (isSameDay) {
    // console.log("same day condition-------------");
    // same day condition
  }

  return { startTime, endTime, filteredStartTimeOptions, filteredEndTimeOptions, isSameDay };
};

export const getStartTimeOpions = (startDate: any, interval: any, oldStartTime: any) => {
  const now = new Date();
  const isToday = now.toDateString() === startDate?.toDateString();
  // const isSameDay = startDate.toDateString() === endDate.toDateString();

  let startTime = "";
  let startTimeOptions = generateTimeOptions(0, 24 * 60 - 30, interval);
  let filteredStartTimeOptions = [];

  if (isToday) {
    const nearestTime = getCurrentTimeNearestInterval(interval);
    const startTimeMinutes = nearestTime.getHours() * 60 + nearestTime.getMinutes();
    startTime =
      startTimeOptions.find((option: any) => parseInt(option.value, 10) >= startTimeMinutes)
        ?.value || "";

    filteredStartTimeOptions = startTimeOptions.filter(
      (option: any) => parseInt(option.value, 10) >= startTimeMinutes
    );
  } else {
    filteredStartTimeOptions = generateTimeOptions(0, 24 * 60 - 30, interval);
    startTime = oldStartTime;
  }
  return { startTime, startTimeOptions: filteredStartTimeOptions };
};
export const getendTimeOpions = (
  startDate: any,
  endDate: any,
  startTime: any,
  oldEndTime: any,
  interval: any
) => {
  const now = new Date();
  const isSameDay = startDate?.toDateString() === endDate?.toDateString();
  let endTime = "";
  let endTimeOptions = generateTimeOptions(0, 24 * 60 - 30, interval);
  let filteredendTimeOptions = [];
  let endTimeMinutes = parseInt(startTime) + 30;
  // logic for handling edge case of 11 and 11.30 PM time
  if (endTimeMinutes >= 24 * 60) {
    endTimeMinutes -= 24 * 60; // This adjusts the endTime to wrap around to the next day
    endTime =
      endTimeOptions.find((option: any) => parseInt(option.value, 10) >= endTimeMinutes)?.value ||
      "";
    filteredendTimeOptions = endTimeOptions.filter(
      (option: any) => parseInt(option.value, 10) >= endTimeMinutes
    );
    return { endTime, endTimeOptions: filteredendTimeOptions };
  }

  if (isSameDay) {
    endTime =
      endTimeOptions.find((option: any) => parseInt(option.value, 10) >= endTimeMinutes)?.value ||
      "";
    filteredendTimeOptions = endTimeOptions.filter(
      (option: any) => parseInt(option.value, 10) >= endTimeMinutes
    );
  } else {
    filteredendTimeOptions = generateTimeOptions(0, 24 * 60 - 30, interval);
    endTime = oldEndTime;
  }
  return { endTime, endTimeOptions: filteredendTimeOptions };
};
// export const getendTimeOpions = (startDate: any, endDate: any, startTime: any, interval: any) => {
//   const now = new Date();
//   const isToday = now.toDateString() === startDate.toDateString();
//   // const isSameDay = startDate.toDateString() === endDate.toDateString();

//   let endTime = "";
//   let endTimeOptions = generateTimeOptions(0, 24 * 60 - 30, interval);
//   let filteredendTimeOptions = [];

//   if (isToday) {
//     // const nearestTime = getCurrentTimeNearestInterval(interval);
//     // const startTimeMinutes = nearestTime.getHours() * 60 + nearestTime.getMinutes();
//     const endTimeMinutes = startTime + 60;
//     // const endTimeMinutes = nearestTime.getHours() * 60 + nearestTime.getMinutes();
//     endTime =
//       endTimeOptions.find((option: any) => parseInt(option.value, 10) >= endTimeMinutes)?.value ||
//       "";
//     console.log("endTime", endTime);
//     filteredendTimeOptions = endTimeOptions.filter(
//       (option: any) => parseInt(option.value, 10) >= endTimeMinutes
//     );
//   } else {
//     filteredendTimeOptions = generateTimeOptions(0, 24 * 60 - 30, interval);
//   }
//   console.log("endTimeMinutes--------------------", endTime, endTimeOptions);
//   return { endTime, endTimeOptions: filteredendTimeOptions };
// };

// export const getTimeOptionsForDate = (startDate:any, endDate:any, interval:any, startTimeOptions:any, endTimeOptions:any) => {
//   const now = new Date();
//   const isToday = now.toDateString() === startDate.toDateString();
//   const isSameDay = startDate.toDateString() === endDate.toDateString();

//   let startTime = "";
//   let endTime = "";
//   let filteredStartTimeOptions = startTimeOptions;
//   let filteredEndTimeOptions = endTimeOptions;

//   if (isToday) {
//     const nearestTime = getCurrentTimeNearestInterval(interval);
//     const startTimeMinutes = nearestTime.getHours() * 60 + nearestTime.getMinutes();
//     const endTimeMinutes = startTimeMinutes + 60; // 1 hour after start time

//     startTime = startTimeOptions.find((option:any) => parseInt(option.value, 10) >= startTimeMinutes)?.value || "";
//     endTime = endTimeOptions.find((option:any) => parseInt(option.value, 10) >= endTimeMinutes)?.value || "";

//     filteredStartTimeOptions = startTimeOptions.filter(
//       (option:any) => parseInt(option.value, 10) >= startTimeMinutes
//     );
//     filteredEndTimeOptions = endTimeOptions.filter(
//       (option:any) => parseInt(option.value, 10) >= endTimeMinutes
//     );
//   } else {
//     filteredStartTimeOptions = generateTimeOptions(0, 24 * 60 - 30, 30);
//     filteredEndTimeOptions = generateTimeOptions(0, 24 * 60 - 30, 30);
//   }

//   return { startTime, endTime, filteredStartTimeOptions, filteredEndTimeOptions, isSameDay };
// };

// export const getTimeOptionsForDate = (
//   startDate: any,
//   interval: any,
//   startTimeOptions: any,
//   endTimeOptions: any
// ) => {
//   const now = new Date();
//   const isToday = now.toDateString() === startDate.toDateString();

//   let startTime = "";
//   let endTime = "";
//   let filteredStartTimeOptions = startTimeOptions;
//   let filteredEndTimeOptions = endTimeOptions;

//   if (isToday) {
//     const nearestTime = getCurrentTimeNearestInterval(interval);
//     const startTimeMinutes = nearestTime.getHours() * 60 + nearestTime.getMinutes();
//     const endTimeMinutes = startTimeMinutes + 60; // 1 hour after start time

//     startTime =
//       startTimeOptions.find((option: any) => parseInt(option.value, 10) >= startTimeMinutes)
//         ?.value || "";
//     endTime =
//       endTimeOptions.find((option: any) => parseInt(option.value, 10) >= endTimeMinutes)?.value ||
//       "";

//     filteredStartTimeOptions = startTimeOptions.filter(
//       (option: any) => parseInt(option.value, 10) >= startTimeMinutes
//     );
//     filteredEndTimeOptions = endTimeOptions.filter(
//       (option: any) => parseInt(option.value, 10) >= endTimeMinutes
//     );
//   } else {
//     filteredStartTimeOptions = generateTimeOptions(0 * 60, 24 * 60 - 15, 30);
//     filteredEndTimeOptions = generateTimeOptions(0 * 60, 24 * 60 - 15, 30);
//   }

//   return { startTime, endTime, filteredStartTimeOptions, filteredEndTimeOptions };
// };

// export const getTimeOptionsForDate = (
//   startDate: any,
//   interval: any,
//   startTimeOptions: any,
//   endTimeOptions: any
// ) => {
//   const now = new Date();
//   const isToday = now.toDateString() === startDate.toDateString();

//   let startTime = "";
//   let endTime = "";
//   let filteredStartTimeOptions = startTimeOptions;
//   let filteredEndTimeOptions = endTimeOptions;

//   if (isToday) {
//     const nearestTime = getCurrentTimeNearestInterval(interval);
//     const startTimeMinutes = nearestTime.getHours() * 60 + nearestTime.getMinutes();
//     const endTimeMinutes = startTimeMinutes + 60; // 1 hour after start time

//     startTime =
//       startTimeOptions.find((option: any) => parseInt(option.value, 10) >= startTimeMinutes)
//         ?.value || "";
//     endTime =
//       endTimeOptions.find((option: any) => parseInt(option.value, 10) >= endTimeMinutes)?.value ||
//       "";

//     filteredStartTimeOptions = startTimeOptions.filter(
//       (option: any) => parseInt(option.value, 10) >= startTimeMinutes
//     );
//     filteredEndTimeOptions = endTimeOptions.filter(
//       (option: any) => parseInt(option.value, 10) >= endTimeMinutes
//     );
//   }

//   return { startTime, endTime, filteredStartTimeOptions, filteredEndTimeOptions };
// };

export const formatDate = (epoch: any) => {
  return dayjs.unix(epoch).format("DD MMM YYYY dddd");
};

export const truncateString = (str: string, num: number) => {
  if (!str) return;
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
};

export const formatDuration = (start_epoc: number, end_epoc: number): string => {
  const totalSeconds = end_epoc - start_epoc;
  const totalHours = Math.floor(totalSeconds / 3600);
  const days = Math.floor(totalHours / 24);
  const hours = totalHours % 24;
  const minutes = Math.floor((totalSeconds % 3600) / 60);

  const daysString = days > 0 ? `${days} d ` : "";
  const hoursString = hours > 0 ? `${hours} h ` : "";
  const minutesString = minutes > 0 ? `${minutes} m ` : "";
  let finalString = daysString;
  if (hoursString && minutesString) {
    finalString += `${hoursString} ${minutesString}`;
  } else if (!hoursString && minutesString) {
    finalString += minutesString;
  } else if (hoursString && !minutesString) {
    finalString += hoursString.trim();
  }
  return finalString.trim();
};

export const convertDateToIST = (dateString: string) => {
  const inputDate = dayjs(dateString, "DD MMM YYYY dddd", "en").tz("Asia/Kolkata");

  // Format the date to the desired output
  const formattedDate = inputDate.format("ddd MMM DD YYYY HH:mm:ss [GMT]Z (z)");

  return formattedDate;
};

export const groupEventsByDate = (events: any) => {
  return events.reduce((acc: any, event: any) => {
    const date = formatDate(
      event?.EventSession?.start_epoc ? event?.EventSession?.start_epoc : event?.start_epoc
    );
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(event);
    return acc;
  }, {});
};

export const getGuestAvatarStatus = (status: string) => {
  const obj: any = {
    pending: "yellow",
    going: "green",
    not_going: "red",
    event_reject: "red",
    waitlist: "grey",
    payment_pending: "yellow",
    payment_processing: "steel",
    payment_failed: "red"
  };
  if (obj[status] && status) {
    return obj[status];
  }
};

export const getCityAndState = (eventSession: EventSession) => {
  if (eventSession?.attend_type === ATTEND_TYPE.TBD) {
    return "";
  }

  const location = eventSession?.location;

  const city =
    location?.locality ||
    location?.sublocality ||
    location?.administrative_area_level_2 ||
    location?.administrative_area_level_3 ||
    "";

  const state = location?.administrative_area_level_1 || "";
  //  added this condition as temporary condition if location object is empty
  return `${city}, ${state}`.trim().replace(/^,|,$/g, "").length > 0
    ? `${city}, ${state}`.trim().replace(/^,|,$/g, "")
    : "Register to see the exact location.";
};

export const locationExtractor = (inPersonAddress: string, location: Location) => {
  let address: string = "";
  if (location?.administrative_area_level_1) {
    address = extractPremiseName(inPersonAddress) + ", " + location?.administrative_area_level_1;
  } else if (location?.administrative_area_level_2) {
    address = extractPremiseName(inPersonAddress) + ", " + location?.administrative_area_level_2;
  } else if (location?.administrative_area_level_3) {
    address = extractPremiseName(inPersonAddress) + ", " + location?.administrative_area_level_3;
  } else {
    address = inPersonAddress!;
  }
  return address;
};

export const getLocationLabel = (eventSession: EventSession) => {
  if (eventSession?.attend_type === ATTEND_TYPE.TBD) {
    return "To Be Decided";
  }

  const location = eventSession?.location;

  return locationExtractor(eventSession?.attend_type_details?.in_person_address, location);
};

export const getGuestPresenceStatus = (status: string) => {
  const obj: any = {
    pending: "away",
    going: "available",
    not_going: "blocked",
    event_reject: "blocked",
    revoke: "blocked",
    waitlist: "away",
    payment_pending: "away",
    payment_processing: "away",
    payment_failed: "blocked",
    going_on_approval: "away"
  };
  if (obj[status] && status) {
    return obj[status];
  }
};

export const convertDateToISO = (isoString: string) => {
  const guessedTimezone = dayjs.tz.guess();

  const relativeTimeStr = dayjs(isoString).tz(guessedTimezone).fromNow();

  const formattedDateStr = dayjs(isoString).tz(guessedTimezone).format("MMMM D, YYYY [at] h:mm A");

  return `${relativeTimeStr} (${formattedDateStr})`;
};

export const getRoleDisplayName = (roleKey: string) => {
  const roleNameMapping: any = {
    featured_guest: "Featured Guest",
    guest: "Guest",
    host: "Host"
  };
  return roleNameMapping[roleKey] || "Guest";
};

export const isValidEmail = (email: string) => {
  // Simple email validation regex
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
export const formatSeconds = (seconds: number) => {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = formatPad(date.getUTCSeconds());
  if (hh) {
    return `${hh}:${formatPad(mm)}:${ss}`;
  }
  return `${mm}:${ss}`;
};

export const formatPad = (string: number) => ("0" + string).slice(-2);
export function getCurrentTimeInTimeZone(timeZone: string): string {
  // const now = new Date();

  // const localFormatter = new Intl.DateTimeFormat("en-US", {
  //   hour: "2-digit",
  //   minute: "2-digit",
  //   hour12: false,
  //   timeZone: timeZone
  // });

  // const gmtFormatter = new Intl.DateTimeFormat("en-US", {
  //   hour: "2-digit",
  //   minute: "2-digit",
  //   hour12: false,
  //   timeZone: "Etc/GMT"
  // });

  // const localTime = localFormatter.format(now);
  // const gmtTime = gmtFormatter.format(now);

  // const localDate = new Date(now.toLocaleString("en-US", { timeZone: timeZone }));
  // const gmtDate = new Date(now.toLocaleString("en-US", { timeZone: "GMT" }));
  // const offsetMs = localDate.getTime() - gmtDate.getTime();

  // const totalOffsetMinutes = offsetMs / (60 * 1000);
  // const offsetHours = Math.floor(Math.abs(totalOffsetMinutes) / 60);
  // const offsetMinutes = Math.abs(totalOffsetMinutes) % 60;

  // const offsetSign = totalOffsetMinutes >= 0 ? "+" : "-";
  // const formattedOffset = `GMT${offsetSign}${offsetHours.toString().padStart(2, "0")}:${offsetMinutes.toString().padStart(2, "0")}`;

  // return `${localTime} (${formattedOffset})`;

  return moment().tz(timeZone).format("hh:mm A ([GMT ]Z)");
}

export const getStartTimeAndEndTime = (startEpoch: number, endEpoch: number) => {
  // Convert input to number if it is a string
  const startEpochNumber = typeof startEpoch === "string" ? parseInt(startEpoch, 10) : startEpoch;
  const endEpochNumber = typeof endEpoch === "string" ? parseInt(endEpoch, 10) : endEpoch;

  // Convert epoch seconds to dayjs object assuming the epoch is in seconds
  const startDateTime = dayjs.utc(startEpochNumber * 1000);
  const endDateTime = dayjs.utc(endEpochNumber * 1000);

  // Format time
  const startTimeFormatted = startDateTime.format("hh:mm A");
  const endTimeFormatted = endDateTime.format("hh:mm A");

  return [startTimeFormatted, endTimeFormatted];
};
export const hexToHexWithAlpha = (hex: string, alpha: number = 1): string => {
  if (!hex) {
    return "";
  }

  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, "");

  // Ensure the hex color is valid
  if (hex.length !== 6) {
    throw new Error("Invalid hex color format");
  }

  // Calculate the alpha value in hex (0-255)
  const alphaHex = Math.round(alpha * 255)
    .toString(16)
    .padStart(2, "0")
    .toUpperCase();

  // Return the hex color with alpha
  return `FF${hex.toUpperCase()}`;
  // return `${alphaHex}${hex.toUpperCase()}`;
};

export const getEventByProxy = (eventId: string) => {
  return `api/v1/proxy/events/e/${eventId}`;
};

export const getCalendarByProxy = (calendarId: string) => {
  return `api/v1/proxy/calendars/e/${calendarId}`;
};

export const convertToRelativeTime = (dateString: string) => {
  return dayjs(dateString).fromNow();
};

export const convertTimestampToRelativeTime = (timestamp: any) => {
  const { _seconds, _nanoseconds } = timestamp;
  const milliseconds = _seconds * 1000 + Math.floor(_nanoseconds / 1000000);
  const date = dayjs(milliseconds);
  return convertToRelativeTime(date.toISOString());
};

export const createInitials = (firstname: string, lastname: string) => {
  // Destructure firstname and lastname from the user object

  // Check if both firstname and lastname are provided
  if (!firstname || !lastname) {
    return;
  }

  // Extract the first character from each and concatenate them
  const initials = firstname.charAt(0) + lastname.charAt(0);

  return initials.toUpperCase(); // Return initials in uppercase
};

export const getRandomColor = (colors: any) => {
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};

export const getStatement = (action: string, name: string) => {
  let statement;

  switch (action) {
    case "calendar_create":
      statement = `${name} has created a new calendar.`;
      break;
    case "calendar_user_added":
      statement = `${name} has been added to the calendar as admin.`;
      break;
    case "calendar_update":
      statement = `${name} has updated the calendar.`;
      break;
    case "calendar_delete":
      statement = `${name} has deleted the calendar.`;
      break;
    case "user_subscribe":
      statement = `${name} has subscribed to the calendar.`;
      break;
    case "user_unsubscribe":
      statement = `${name} has unsubscribed from the calendar.`;
      break;
    case "calendar_user_remove":
      statement = `${name} has been removed from the calendar.`;
      break;
    case "calendar_event_added":
      statement = `${name} has added a new event to the calendar.`;
      break;
    case "calendar_event_updated":
      statement = `${name} has updated an event on the calendar.`;
      break;
    case "calendar_event_removed":
      statement = `${name} has removed an event from the calendar.`;
      break;
    default:
      statement = `${name} performed an unknown action.`;
      break;
  }

  return statement;
};

type JsonItem = { insert: string };
type JsonArray = JsonItem[];

export function isInsertValueEmpty(json: string): boolean {
  try {
    // Parse the JSON data
    const parsedData: any = JSON.parse(json);

    // Validate if parsedData is an array of the expected type
    if (
      Array.isArray(parsedData) &&
      parsedData.every(
        (item): item is JsonItem => "insert" in item && typeof item.insert === "string"
      )
    ) {
      return parsedData.every((item) => !item.insert.trim());
    }

    // If not an array or if the array does not contain the expected objects
    return false;
  } catch (e) {
    console.error("Failed to parse JSON:", e);
    return false; // Optionally handle the error as needed
  }
}

export const convertUnixTimestampToMinutes = (unixTimestamp: number, timeZone: string) => {
  const date = moment.unix(unixTimestamp);
  const startOfDay = date.clone().tz(timeZone).startOf("day");
  const minutesForDay = date.diff(startOfDay, "minutes");
  return minutesForDay;
};

export const convertLocationToObject = (data: any) => {
  const result = {
    latLng: { lat: 0, lng: 0 },
    country: "",
    placeId: "",
    locality: "",
    sublocality: "",
    administrative_area_level_1: "",
    administrative_area_level_2: "",
    administrative_area_level_3: "",
    description: ""
  };

  if (data?.geometry?.location) {
    const { location } = data.geometry;

    result.latLng.lat = typeof location?.lat === "function" ? location?.lat?.() : location?.lat;
    result.latLng.lng = typeof location?.lng === "function" ? location?.lng?.() : location?.lng;
  }

  if (data && data?.place_id) {
    result.placeId = data.place_id;
  }

  data?.address_components?.forEach((item: any) => {
    if (item?.types?.includes("locality")) {
      result.locality = item?.long_name;
      result.description = item?.long_name;
    }
    if (item?.types?.includes("sublocality")) {
      result.sublocality = item?.long_name;
    }
    if (item?.types?.includes("administrative_area_level_1")) {
      result.administrative_area_level_1 = item?.long_name;
    }
    if (item?.types?.includes("administrative_area_level_3")) {
      result.administrative_area_level_3 = item?.long_name;
    }
    if (item?.types?.includes("country")) {
      result.country = item?.long_name;
    }
  });

  return result;
};

export const getLocationType = (data: any) => {
  const { in_person_address, virtual_address } = data;
  if (in_person_address && virtual_address) {
    return "Hybrid";
  } else if (in_person_address) {
    return "In Person";
  } else if (virtual_address) {
    return "Virtual";
  } else {
    return "";
  }
};

export const getLocationTypeForAPI = (data: any, applicationId?: string) => {
  const { in_person_address, virtual_address } = data;
  if (in_person_address && (virtual_address || applicationId)) {
    return "hybrid";
  } else if (in_person_address) {
    return "in_person";
  } else if (virtual_address || applicationId) {
    return "virtual";
  } else {
    return "tbd";
  }
};

export const getIllustrationImageURL = () => {
  const fileName = Math.floor(Math.random() * 34) + 1;
  return `${IMAGE_URL}/illustration_lib/${fileName > 35 ? 1 : fileName}.png`;
};

export function generateRandomAlias(length: number) {
  const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export function generateUniqueAlias(attempt = 1, maxAttempts = 15) {
  if (attempt > maxAttempts) {
    return null; // Exceeded maximum attempts
  }

  const randomAlias = generateRandomAlias(7); // Generate 7-character alias
  return randomAlias;
}

// Generate a unique alias based on the event name
export function generateAliasBasedOnName(eventName: string, attempt = 1, maxAttempts = 15) {
  // if (attempt > maxAttempts) {
  //   return generateUniqueAlias(); // Fallback to generating a random alias
  // }

  let aliasName = eventName
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/^-+|-+$/g, "");
  if (aliasName.length > 30) {
    aliasName = aliasName.substring(0, 30); // Ensure alias does not exceed 30 characters
  }
  return aliasName;
}

export const formatReminderString = (
  eventEpoc: number,
  reminderSeconds: number,
  timeZone = "GMT+5:30",
  isPost: boolean
) => {
  const eventDate = dayjs.unix(eventEpoc).tz(timeZone);
  const reminderDate = isPost
    ? eventDate.add(reminderSeconds, "second")
    : eventDate.subtract(reminderSeconds, "second");
  const reminderDuration = dayjs.duration(reminderSeconds * 1000);
  let timePrior;
  if (reminderDuration.asDays() >= 1) {
    const days = Math.floor(reminderDuration.asDays());
    const hours = Math.floor(reminderDuration.asHours() % 24);
    if (hours > 0) {
      timePrior = `${days} day${days > 1 ? "s" : ""} and ${hours} hour${hours > 1 ? "s" : ""}`;
    } else {
      timePrior = `${days} day${days > 1 ? "s" : ""}`;
    }
  } else if (reminderDuration.asHours() >= 1) {
    const hours = Math.floor(reminderDuration.asHours());
    timePrior = `${hours} hour${hours > 1 ? "s" : ""}`;
  } else {
    const minutes = Math.floor(reminderDuration.asMinutes());
    timePrior = `${minutes} minute${minutes > 1 ? "s" : ""}`;
  }

  const type = isPost ? "after the end of the event" : "before the start of the event";

  const reminderString = reminderDate.format(`dddd, MMMM D [at] hh:mm A [(GMT ${timeZone})]`);
  return `The reminder will trigger on ${reminderString}, ${timePrior} ${type}.`;
};

export const isReminderAfterEvent = (eventEpoc: number, reminderSeconds: number): boolean => {
  const eventDate = dayjs.unix(eventEpoc);
  const reminderDate = eventDate.subtract(reminderSeconds, "second");
  const now = dayjs();
  return !reminderDate.isAfter(now);
};

export const isEventMoreThanOneYearAway = (epochTime: number, seconds: number): boolean => {
  const startDate = dayjs.unix(epochTime);
  const dateAfter365Days = startDate.add(365, "day");
  const endDate = startDate.add(seconds, "second");
  return endDate.isAfter(dateAfter365Days);
};

const findOptionByTimeZone = (options: any, timeZone: string) =>
  options?.find((option: any) => option.value === timeZone);

export const showTimeZoneAbbr = (value: any, options: any) => {
  const selectedTimezoneOption = findOptionByTimeZone(options, value);
  return selectedTimezoneOption
    ? `(GMT${selectedTimezoneOption?.offset}) ${selectedTimezoneOption.value}  (${selectedTimezoneOption?.abbrev})`
    : "Select Timezone";
};

export const prepareGetParticipantsURL = (data: {
  eventId: string;
  roles?: string;
  sortBy?: string[];
  status?: string;
  searchText?: string;
  details?: boolean;
  limit?: number;
  page?: number;
  searchParam?: string;
  searchValue?: string;
  checkin_epoc?: boolean;
}) => {
  return `api/v2/events/${data?.eventId}/event-participations?roles=${data.roles || ""}&statuses=${data.status || ""}&name=${encodeURIComponent(data.searchText || "")}&sort=${
    data.sortBy && data.sortBy.length ? data.sortBy.map((item) => `${item}:ASC`).join(",") : ""
  }${data.details === false ? "&details=false" : ""}${
    data.searchParam && data.searchValue
      ? "&searchParam=" + data.searchParam + "&searchValue=" + data.searchValue
      : ""
  }${typeof data.limit === "number" ? "&limit=" + data.limit : ""}${
    typeof data.page === "number" ? "&page=" + data.page : ""
  }${data.checkin_epoc === true ? "&checkin_epoc=true" : ""}`;
};

export const hasEventStarted = (startEpoch: number) => {
  if (!startEpoch) return false;
  const now = dayjs();
  const startTime = dayjs.unix(startEpoch);
  return now.isAfter(startTime);
};

export const hasEventEnded = (endEpoch: number) => {
  if (!endEpoch) return false;
  const now = dayjs();
  const endTime = dayjs.unix(endEpoch);
  return now.isAfter(endTime);
};

export const timeAgo = (updatedAt: string) => {
  const now = dayjs();
  const updatedTime = dayjs(updatedAt);

  const diffInMinutes = now.diff(updatedTime, "minute");
  const diffInHours = now.diff(updatedTime, "hour");
  const diffInDays = now.diff(updatedTime, "day");

  if (diffInMinutes < 60) {
    return `${diffInMinutes} minute${diffInMinutes !== 1 ? "s" : ""} ago`;
  } else if (diffInHours < 24) {
    return `${diffInHours} hour${diffInHours !== 1 ? "s" : ""} ago`;
  } else {
    return `${diffInDays} day${diffInDays !== 1 ? "s" : ""} ago`;
  }
};

export const isEventOngoing = (start_epoc: string, end_epoc: string) => {
  if (start_epoc && end_epoc) {
    const localTimezone = dayjs.tz.guess();

    const startTime = dayjs.unix(parseInt(start_epoc)).tz(localTimezone);

    const endTime = dayjs.unix(parseInt(end_epoc)).tz(localTimezone);

    const now = dayjs().tz(localTimezone);

    const isStarted = startTime.isBefore(now);
    const isEnded = endTime.isAfter(now);
    return isStarted && isEnded;
  }
  return false;
};

export const DEFAULT_DES_CAL = `This is your all-in-one hub to stay connected and organized! Whether it's meetups, exclusive workshops, or important announcements, you’ll have everything in one place. Easily track upcoming events, set reminders, and avoid missing out on what’s happening within our vibrant community.`;

export const validatePermission = (
  status: string,
  permissionKey: string,
  privacy: any,
  role: string
) => {
  const permissions: any = {
    location_visibility: privacy?.location_visibility,
    meeting_link_visibility: privacy?.meeting_link_visibility,
    guest_list_visibility: privacy?.guest_list_visibility
  };

  if (role === "featured_guest" || role === "host" || role === "creator") {
    return true;
  }

  if (!permissions[permissionKey]) {
    return false;
  }

  if (permissions[permissionKey] === "anyone") {
    return true;
  }

  const isRegistered = status === "going";

  if (permissions[permissionKey] === "registered_guests" && role === "guest" && isRegistered) {
    return true;
  }

  return false;
};

export const downloadAndCreateFile = async (imageUrl?: string): Promise<File | null> => {
  try {
    const response = await fetch(`${imageUrl}`);
    const blob = await response.blob();
    if (!blob) return null;

    return new File([blob], v4(), { type: "image/png" });
  } catch (error) {
    console.error("Error downloading image:", error);
    return null;
  }
};

export const calculateRatingPercentage = (averageRating?: number, totalRating?: number): string => {
  if (!averageRating || !totalRating || totalRating === 0) {
    return "0%";
  }

  const percentage = (averageRating / totalRating) * 100;
  return `${percentage.toFixed(2)}%`;
};
